<template>
  <van-popup v-model="visibility" class="dialog-confirm">
    <div class="content">
      <div class="header">{{ title }}</div>
      <div class="message">
        <div class="title">一、业务介绍</div>
        <div class="text">1、沃畅游99至尊会员 ，<span>25元/月</span>。订购成功立即生效，不退订连续包月，退订成功后月底失效。</div>
        <div class="text">2、沃畅游99至尊会员为游戏玩家提供专享服务：</div>
        <div class="text">①沃畅游客户端/盒子端（15小时，根据具体活动页面规则）小时主机时长，手游不限时畅玩；</div>
        <div class="text">②热门权益每月任选，当月可领，次月可更换不同权益：<span>注：热门特权礼包内容不定期更新调整，具体权益内容以权益兑换页面内容为准；特权礼包需每月自行领取，逾期不可补领上月权益；</span></div>
       
        <div class="title">二、适用范围</div>
        <div class="text">（一）适用对象：</div>
        <div class="text">仅限联通手机用户，包括4G、5G、及3G/4G、4G/5G融合套餐用户；</div>
        <div class="text">（二）办理范围：</div>
        <div class="text">全国用户。</div>
        
        <div class="title">三、使用规则</div>
        <div class="text"><span>（一）沃畅游时长：【目前仅支持安卓用户，IOS用户请关注其他活动，为您带来的不便敬请理；】无需领取，订购成功自动下发，续订期间每月5号前自动下发；使用订购手机号，登录联通畅游客户端即可查看、使用；</span></div>
        <div class="text"><span>（二）特权礼包N选2：</span></div>
        <div class="text"><span>①影音视听类：芒果TV会员 (PC移动影视)月卡、酷我音乐月卡、芒果TV会员(PC移动影视)周卡、搜狐视频月卡、哗哩哗哩月卡、优酷视频会员周卡、爱奇艺视频月卡、优酷视频月卡、腾讯视频月卡；</span></div>
        <div class="text"><span>②生活类：QO普通会员月卡、QQ黄钻会员月卡、百度文库会员月卡。</span></div>
        <div class="text"><span>注：热门权益会有所调整，具体权益内容以权益兑换页面内容为准，特权礼包需每月自行领取，逾期不可补领上月权益。</span></div>
        
        <div class="title">四、退订方式：</div>
        <div class="text"><span>1、退订渠道：中国联通APP、联通营业厅、400-1189599客服热线。</span></div>
        <div class="text"><span>2、退订规则：退订、变更次月生效。</span></div>
        <div class="text"><span>产品服务:联通在线信息科技有限公司</span></div>
        <div class="text"><span>产品支持:浙江秋未信息科技有限公司</span></div>
      </div>
      <div class="foot"  @click="close">确认</div>
    </div>
  </van-popup>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    close() {
      this.$emit("update:visibility", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  &-confirm {
    box-sizing: border-box;
    width: 642px;
    overflow-x: hidden;
    border-radius: 10px;
    .content{
      margin: 0 auto;
      width: 100%;
      height: 710px;
      overflow: hidden;
      font-size: 26px;
      .header{
        width: 100%;
        height: 100px;
        text-align: center;
        line-height: 100px;
      }
      .message{
        width: 100%;
        height: 500px;
        overflow-y: scroll;
        padding: 30px;
        box-sizing: border-box;
        .text{
          .title{
            font-weight: 700;
          }
          span{
            color: red;
          }
        }
      }
      .foot {
        width: 100%;
        height: 100px;
        text-align: center;
        line-height: 100px;
        color: red;
        font-size: 30px;
        border-top: 1px solid #dddddd;
      }
    }
  }
}
</style>
